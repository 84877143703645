import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PageHeader from '../components/pageHeader'
import PageTemplate from '../components/pageTemplate'
import {DynamicSidebar} from '../components/dynamicSidebar'

export default ({ data, location }) => {
  const {title, content, excerpt} = data.wordpressPost
  return (
    <Layout title={title} description={excerpt.replace(/(<([^>]+)>)/ig,"")}>
      <PageHeader title={title}/>
      <PageTemplate content={content} Sidebar={DynamicSidebar} location={location}/>
      <DynamicSidebar/>
    </Layout>
  )
}

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPost(wordpress_id: {eq: $wordpress_id}) {
      title
      content
      excerpt
    }
  }
`
